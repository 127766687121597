import { strings } from '@constants'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'
import ReactQuill from 'react-quill'

import { DuoWrapper, FooterWrapper, PsuedoFullHeightWrapper, QuilWrapper, Section, Wrapper } from '../styles'
import { Button, Input, TOAST_TYPE } from '@base'
import { Devices } from '@styles'
import { getGlossaryById } from '@data/conn/get/glossary'
import { showLoader, showToast } from '@data/state/action'
import { createGlossary } from '@data/conn/post/glossary'
import { useForceUpdate, useHeaderMeta } from '@hooks'
import { editGlossary } from '@data/conn/put/glossary'
import { adminGlossary } from '@utils'

const ReactQuillWapper = styled(ReactQuill)`
	height: auto;
	max-height: auto;
	@media ${Devices.mobile} {
		height: 90%;
		max-height: 90%;
	}
`

let toolbarOptions = [
	[{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
	['bold', 'italic', 'underline', 'strike'], // toggled buttons
	['link', 'image'],
	[
		([({ header: 1 }, { header: 2 })], // custom button values
		{ list: 'bullet' }),
	],
	[
		([({ header: 1 }, { header: 2 })], // custom button values
		{ list: 'ordered' }),
	],
	[{ color: [] }, { background: [] }], // dropdown with defaults from theme
	[{ align: [] }],
]

const deriveFormState = (data) => {
	let _state = {
		keyword: {
			id: 'keyword',
			label: strings('keyword'),
			placeholder: strings('enter', 'keyword'),
			value: '',
			required: true,
		},
		slug: {
			id: 'slug',
			label: strings('slug'),
			placeholder: strings('enter', 'slug'),
			value: '',
			required: true,
		},
		description: {
			id: 'description',
			label: strings('description'),
			placeholder: strings('enter', 'description'),
			value: '',
			required: true,
		},
	}

	if (data) {
		_state.keyword.value = data?.keyword
		_state.slug.value = data?.slug
		_state.description.value = data?.description
	}

	return _state
}

const AddGlossary = () => {
	useHeaderMeta(adminGlossary)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const loggedInUser = useSelector((state) => state.getIn(['user', 'user']))
	const [forceUpdate, update] = useForceUpdate()
	const [glossary, setGlossary] = useState({})

	const [formState, setFormState] = useState(deriveFormState())
	const { glossaryId } = useParams()

	useEffect(() => {
		if (glossaryId) {
			dispatch(showLoader(true))
			getGlossaryById(glossaryId)
				.then((response) => {
					setFormState(deriveFormState(response))
					setGlossary(response)
				})
				.catch((error) => {
					console.log(error)
				})
				.finally(() => {
					dispatch(showLoader(false))
				})
		}
	}, [glossaryId, forceUpdate])

	const updateState = (key) => (value) => {
		if (key === 'keyword') {
			value = value.replace(/^\s+/g, '').toLowerCase() //regex -> keyword will not start from space
		}
		setFormState((_state) => ({ ..._state, [key]: { ..._state[key], value } }))
		if (key === 'keyword') {
			updateState('slug')(value.replaceAll(/[0-9]/g, '').replaceAll(' ', '-').replaceAll(/-+/g, '-'))
		}
	}

	const formStateToData = (edit) => {
		let _data = {
			keyword: formState.keyword.value,
			slug: formState.slug.value,
			description: formState.description.value,
		}
		if (edit) {
			_data = { ..._data, updated_by: loggedInUser.user_id, updated_at: new Date().getTime() }
		} else {
			_data = { ..._data, created_by: loggedInUser.user_id, created_at: new Date().getTime() }
		}
		return _data
	}

	const addGlossaryHandler = () => {
		dispatch(showLoader(true))
		const _glossary = formStateToData()
		createGlossary(_glossary)
			.then((response) => {
				dispatch(showToast(true, strings('msg_glossary_added_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				let msg = error?.message || strings('msg_glossary_added_failed')
				dispatch(showToast(true, msg, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				navigate(-1)
				dispatch(showLoader(false))
			})
	}

	const editGlossaryHandler = () => {
		dispatch(showLoader(true))
		const _data = formStateToData(true)
		editGlossary({ ...glossary, ..._data })
			.then((response) => {
				dispatch(showToast(true, strings('msg_edit_glossary_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((error) => {
				let error_msg = error?.message || strings('msg_edit_glossary_failure')
				dispatch(showToast(true, error_msg, { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				update()
				dispatch(showLoader(false))
			})
	}

	const disabled = useMemo(() => {
		return Object.values(formState).some((d) => d.required && !d.value) || formState.description?.value?.length < 20
	}, [formState])

	return (
		<Wrapper>
			<PsuedoFullHeightWrapper>
				<DuoWrapper>
					<Section>
						<Input
							id={formState.keyword.id}
							label={formState.keyword.label}
							placeholder={formState.keyword.placeholder}
							value={formState.keyword.value}
							onChange={updateState('keyword')}
							required={formState.keyword.required}
						/>
						<Input
							id={formState.slug.id}
							label={formState.slug.label}
							placeholder={formState.slug.placeholder}
							value={formState.slug.value}
							onChange={updateState('slug')}
							required={formState.slug.required}
							pattern='^([a-z]|-)*$'
							errorMap={{
								patternMismatch: strings('enter_valid_slug'),
							}}
						/>
					</Section>
					<Section fullWidth>
						<QuilWrapper>
							<ReactQuillWapper
								theme='snow'
								placeholder={formState?.description?.placeholder}
								value={formState?.description?.value}
								onChange={updateState('description')}
								modules={{ toolbar: toolbarOptions }}
							/>
						</QuilWrapper>
					</Section>
				</DuoWrapper>
			</PsuedoFullHeightWrapper>
			<FooterWrapper>
				{glossaryId ? (
					<Button small xs type='primary' onClick={editGlossaryHandler} disabled={disabled}>
						{strings('edit')}
					</Button>
				) : (
					<Button small xs type='primary' onClick={addGlossaryHandler} disabled={disabled}>
						{strings('add')}
					</Button>
				)}
			</FooterWrapper>
		</Wrapper>
	)
}

export default AddGlossary
